import React from 'react'
import * as styles from '../styles/covid19Announcement.module.scss'
import CheckIcon from '../icons/check'

export default function Covid19announce(props) {
  return (
    <li className={styles.announce_item}>
      <span className={styles.announce_icon}>
        <CheckIcon width={'100%'} height={'100%'} />
      </span>
      <span className={styles.announce_text}>
        {props.text}
      </span>
    </li>
  )
}
