import React from 'react'
import * as styles from '../styles/covid19Measures.module.scss'

export default function Covid19Measures({measuresTitle, measuresText, measuresImage}) {
  return (
    <>
      <li className={styles.measures_item}>
        <div className={styles.measures_img}>
          {measuresImage}
        </div>
        <div className={styles.measures_desc}>
          <h3>{measuresTitle}</h3>
          <p>{measuresText}</p>
        </div>
      </li>
    </>
  )
}
