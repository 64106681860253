import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Seo from '../components/seo'
import ReserveButton from '../components/reserveButton'
import Covid19Measures from '../components/covid19Measures'
import Covid19Announcement from '../components/covid19Announcement'
import Machine from '../components/machine'
import CourseItem from '../components/courseItem'
import Faq from '../components/faq'
import * as styles from '../styles/index.module.scss'

import Logo from '../icons/logo'
import Mask from '../icons/mask'
import Thermo from '../icons/thermo'
import Disinfect from '../icons/disinfect'
import ConceptHeading from '../icons/conceptHeading'
import PointHeading from '../icons/pointHeading'
import CourseHeading from '../icons/courseHeading'
import FaqHeading from '../icons/faqHeading'
import AccessHeading from '../icons/accessHeading'
import Num01 from '../icons/num01'
import Num02 from '../icons/num02'
import Num03 from '../icons/num03'
import InstagramIcon from '../icons/instagram'

export default function Home() {
  const covid19MeasuresInfo = [
    {
      measuresTitle: 'マスク着用',
      measuresText: 'スタッフのマスク着用を徹底しております',
      measuresImage: <Mask />
    },
    {
      measuresTitle: 'スタッフの体調管理',
      measuresText: 'スタッフの体調管理を徹底しております',
      measuresImage: <Thermo />
    },
    {
      measuresTitle: '手洗い・消毒',
      measuresText: 'スタッフのこまめな手洗い、設備の消毒に努めております',
      measuresImage: <Disinfect />
    }
  ]
  const renderCovid19Measures = covid19MeasuresInfo.map( (item, idx) => {
    return (
      <Covid19Measures
        key={idx}
        {...item}
      />
    )
  })
  const covid19AnnouncementInfo = [
    '開店前に換気と消毒を徹底しております',
    '定期的に換気をしております',
    '定期的に店内の消毒をしております'
  ]
  const renderCovid19Announcement = covid19AnnouncementInfo.map( (item, idx) => {
    return (
      <Covid19Announcement
        key={idx}
        text={item}
      />
    )
  })

  const machineInfo = [
    {
      machineTitle: 'VICTORIA',
      machineText: 'NPL方式で痛みが少なく男性脱毛にも強い！\nハイスペック脱毛器VICTORIA',
      machineImage: <StaticImage src='../images/machine_victoria.png' alt='VICTORIA' placeholder='blurred' layout='fullWidth' />
    },
    {
      machineTitle: 'コラーゲンカプセル',
      machineText: `全身フォトフェイシャル！\n光のエステで全身スベスベお肌に！`,
      machineImage: <StaticImage src='../images/machine_seecret.png' alt='コラーゲンカプセル' placeholder='blurred' layout='fullWidth' objectFit='contain' />
    },
    {
      machineTitle: 'ホワイトニング',
      machineText: '青色LEDの力で、着色汚れを浮かせて落とします！\n痛くない、歯のセルフホワイトニングです。',
      machineImage: <StaticImage src='../images/machine_whitening.png' alt='ホワイトニング' placeholder='blurred' layout='fullWidth' objectFit='contain' />
    }
  ]
  const renderMachine = machineInfo.map( (item, idx) => {
    return (
      <Machine
        key={idx}
        {...item}
      />
    )
  })

  const courseInfo = [
    {
      courseTitle: '脱毛',
      courseText: '脱毛初心者でも安心。\n高性能「冷却プレート」で痛みのない方法で施術を行います。\n女性だけでなく、男性の脱毛もお任せください。',
      courseLink: ''
    },
    {
      courseTitle: 'コラーゲンカプセル',
      courseText: '医療現場でも導入されている安全性の高いマシンを採用しております。\n加齢によりコラーゲンが減少することが、お肌のたるみ、乾燥につながります。光を浴びるだけでお肌を活性化させ、ターンオーバーを正常に戻していきます。\n20〜30分マシンの中で横になるだけ。簡単ケアも人気の秘密デス。',
      courseLink: ''
    },
    {
      courseTitle: 'ホワイトニング',
      courseText: 'ホワイト溶液に食品添加物に使用される酸化チタンを採用。LEDを当てることで光触媒を発生させます。\n汚れ分解機能、抗菌殺菌機能、消臭機能に優れ、本来の美しい歯の白さへと導きます。',
      courseLink: ''
    }
  ]
  const renderCourse = courseInfo.map( (item, idx) => {
    return (
      <CourseItem
        key={idx}
        {...item}
      />
    )
  })

  const faqList = [
    {
      question: 'どんな人が脱毛に通っていますか？',
      answer: '10代〜60代まで幅広い年齢層の方にご来店頂いています。'
    },
    {
      question: '必要な持ち物などはありますか？',
      answer: '特にありません。手ぶらでOKです。'
    },
    {
      question: 'どの程度通えば効果が出ますか？',
      answer: '1度の施術でも効果はございます。また、10回程の施術でほぼ気にならなくなるくらいの脱毛効果が見込めます。 ※個人差あり ※11回目以降の施術についてはアフターフォローがございます。'
    },
    {
      question: '脱毛の際に痛みを感じることはありますか？',
      answer: '当店では最新の機械を導入しており、痛みはほぼありません。 じんわり温かい程度です。'
    }
  ]
  const renderFaq = faqList.map( (item, idx) => {
    return (
      <Faq
        key={idx}
        {...item}
      />
    )
  })

  // メインコンテンツ
  return (
    <Layout>
      <Seo />
      <div className={styles.mv_container}>
        <h1 className={styles.mv_heading}>
          <Logo />
        </h1>
        <div className={styles.mv_box}>
          <div className={styles.mv_imagebox}>
            <StaticImage
              src='../images/mainvisual01.png'
              alt=''
              placeholder='blurred'
              layout='fullWidth'
            />
          </div>
          <div className={styles.mv_imagebox}>
            <StaticImage
              src='../images/mainvisual02.png'
              alt=''
              placeholder='blurred'
              layout='fullWidth'
            />
          </div>
        </div>
      </div>

      <ReserveButton />

      <div className={styles.covid19_wrapper}>
        <section className={`${styles.contents_container} ${styles.covid19_container}`}>
          <h2 className={styles.covid19_heading}>新型コロナウイルス<br />感染防止対策の取り組みについて</h2>
          <ul className={styles.covid19_measures_list}>
            {renderCovid19Measures}
          </ul>
          <ul className={styles.covid19_announcement_list}>
            {renderCovid19Announcement}
          </ul>
        </section>
      </div>

      <div className={styles.machine_wrapper}>
        <section className={`${styles.contents_container} ${styles.machine_container}`} id="machine">
          <h2 className={styles.machine_heading}>最先端のマシンを導入</h2>
          <p className={styles.machine_subheading}>美しさにこだわる上質なサロンで最新の美容体験を。</p>
          <ul className={styles.machine_list}>
            {renderMachine}
          </ul>
        </section>
      </div>

      <div className={styles.concept_wrapper}>
        <section className={`${styles.contents_container} ${styles.concept_container}`} id="concept">
          <h2 className={styles.contents_heading}>
            <span className={styles.contents_heading_img}>
              <ConceptHeading />
            </span>
            <span className={styles.contents_heading_txt}>コンセプト</span>
          </h2>
          <div className={styles.concept_box}>
            {/* <img src="https://via.placeholder.com/400x300" /> */}
            <div className={styles.concept_textbox}>
              <h3 className={styles.concept_textbox_heading}>パーティーズサロンは<br />みんなの『キレイ』を応援します。</h3>
              <p className={styles.concept_textbox_text}>老若男女を問わず、みなさんの「キレイになりたい」「美しくなりたい」をサポートいたします！</p>
            </div>
          </div>
        </section>
      </div>

      <div className={styles.point_wrapper}>
        <section className={`${styles.contents_container} ${styles.point_container}`} id="point">
          <h2 className={styles.contents_heading}>
            <span className={styles.contents_heading_img}>
              <PointHeading />
            </span>
            <span className={styles.contents_heading_txt}>選ばれる理由</span>
          </h2>
          <ul className={styles.point_list}>

            <li className={styles.point_item}>
              <div className={styles.point_image}>
                <StaticImage
                  src='../images/point_01.png'
                  alt=''
                  placeholder='blurred' layout='fullWidth'
                />
              </div>
              <div className={styles.point_textbox}>
                <h3 className={styles.point_title}>
                  <span className={styles.point_title_icon}>
                    <Num01 />
                  </span>
                  <span className={styles.point_title_text}>最新マシンを導入</span>
                </h3>
                <p className={styles.point_desc}>当サロンでは、高性能脱毛器VICTORIA(ヴィクトリア)を使用し、より良い環境でお客様に最新の美容をお届けしています。</p>
              </div>
            </li>

            <li className={styles.point_item}>
              <div className={styles.point_image}>
                {/* <StaticImage
                  src='../images/point_02.png'
                  alt=''
                  placeholder='blurred' layout='fullWidth'
                /> */}
              </div>
              <div className={styles.point_textbox}>
                <h3 className={styles.point_title}>
                  <span className={styles.point_title_icon}>
                    <Num02 />
                  </span>
                  <span className={styles.point_title_text}>予約がとりやすい</span>
                </h3>
                <p className={styles.point_desc}>当サロンでは、施術時間の短い脱毛機と最短15分で効果を実感できる痩身機器を導入しております。<br />通常3時間かかる全身脱毛が10分（※照射時間のみ）で完了し、1時間以上かけて行う痩身メニューも最短15分で実感いただけます。</p>
              </div>
            </li>

            <li className={styles.point_item}>
              <div className={styles.point_image}>
                <StaticImage
                  src='../images/point_04.png'
                  alt=''
                  placeholder='blurred' layout='fullWidth'
                />
              </div>
              <div className={styles.point_textbox}>
                <h3 className={styles.point_title}>
                  <span className={styles.point_title_icon}>
                    <Num03 />
                  </span>
                  <span className={styles.point_title_text}>美肌効果</span>
                </h3>
                <p className={styles.point_desc}>機器により血液循環が良くなりお肌の代謝が促進されます。美肌効果もあるので、肌荒れでお悩みの方にもオススメです。</p>
              </div>
            </li>

          </ul>
        </section>
      </div>

      <div className={styles.course_wrapper}>
        <section className={`${styles.contents_container} ${styles.course_container}`} id="course">
          <h2 className={styles.contents_heading}>
            <span className={styles.contents_heading_img}>
              <CourseHeading />
            </span>
            <span className={styles.contents_heading_txt}>コース一覧</span>
          </h2>
          <ul className={styles.course_list}>
            {renderCourse}
          </ul>
        </section>
      </div>

      <ReserveButton />

      <div className={styles.faq_wrapper}>
        <section className={`${styles.contents_container} ${styles.faq_container}`} id="faq">
          <h2 className={styles.contents_heading}>
            <span className={styles.contents_heading_img}>
              <FaqHeading />
            </span>
            <span className={styles.contents_heading_txt}>よくある質問</span>
          </h2>
          <ul className={styles.faq_list}>
            {renderFaq}
          </ul>
        </section>
      </div>

      <div className={styles.access_wrapper}>
        <section className={`${styles.contents_container} ${styles.access_container}`} id="access">
          <h2 className={styles.contents_heading}>
            <span className={styles.contents_heading_img}>
              <AccessHeading />
            </span>
            <span className={styles.contents_heading_txt}>店舗情報</span>
          </h2>
          <div className={styles.access_box}>
            <div className={styles.access_imgbox}>
              {/* <img src="https://via.placeholder.com/400x300" /> */}
            </div>
            <div className={styles.access_txtbox}>
              <h3>Party's Salon</h3>
              <span>パーティーズサロン</span>
              <dl className={styles.access_detail_table}>
                <dt>営業時間</dt>
                <dd>10:00 - 20:00</dd>
                <dt>住所</dt>
                <dd>
                  〒900-0014<br />沖縄県那覇市松尾1-4-1<br />コラムビル 3F
                </dd>
              </dl>
            </div>
          </div>
        </section>
      </div>

      <div className={styles.sns_wrapper}>
        <ReserveButton />

        <div className={styles.sns_container}>
          <a className={styles.sns_anchor} href="https://www.instagram.com/partyssalon/" target="_blank" rel="noopener noreferrer">
            <InstagramIcon />
          </a>
          <span className={styles.sns_text}>FOLLOW ME!</span>
        </div>
      </div>
    </Layout>
  )
}
