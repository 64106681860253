import React from 'react'
import {Link} from 'gatsby'
import * as styles from '../styles/courseItem.module.scss'
import ReadmoreIcon from '../icons/readmore'

export default function Course({courseTitle, courseText, courseLink}) {
  return (
    <li className={styles.course_item}>
      <div className={styles.course_container}>
        <h3>{courseTitle}</h3>
        <p>
          {courseText.split('\n').map((str, idx) => (
            <React.Fragment key={idx}>{str}<br /></React.Fragment>
          ))}
        </p>
        <Link to='/course/' className={styles.course_link}>
          <ReadmoreIcon />
        </Link>
      </div>
    </li>
  )
}
