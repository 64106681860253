import React from 'react'

export default function DisinfectIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 125">
      <g id="manner_disinfect" transform="translate(-954 -1375)">
        <circle id="楕円形_6" data-name="楕円形 6" cx="62.5" cy="62.5" r="62.5" transform="translate(954 1375)" fill="#97b1ab"/>
        <g id="グループ_147" data-name="グループ 147" transform="translate(987 1400)">
          <g id="グループ_119" data-name="グループ 119" transform="translate(0.361 37.496)">
            <g id="グループ_115" data-name="グループ 115">
              <path id="パス_114" data-name="パス 114" d="M363.422,245.877h-29.97V221.109h29.97Zm-27.561-2.409h25.152V223.518H335.861Z" transform="translate(-333.452 -221.109)" fill="#fff"/>
            </g>
            <g id="グループ_118" data-name="グループ 118" transform="translate(8.657 6.056)">
              <g id="グループ_116" data-name="グループ 116" transform="translate(4.407)">
                <rect id="長方形_171" data-name="長方形 171" width="3.841" height="12.655" fill="#fff"/>
              </g>
              <g id="グループ_117" data-name="グループ 117" transform="translate(0 4.407)">
                <rect id="長方形_172" data-name="長方形 172" width="3.841" height="12.655" transform="translate(0 3.841) rotate(-90)" fill="#fff"/>
              </g>
            </g>
          </g>
          <g id="グループ_146" data-name="グループ 146" transform="translate(0 0)">
            <g id="グループ_120" data-name="グループ 120" transform="translate(0 22.48)">
              <path id="パス_115" data-name="パス 115" d="M356.76,256.35H339.987a6.968,6.968,0,0,1-6.96-6.96V211.755a1.568,1.568,0,0,1,.342-.977l5.394-6.75a1.566,1.566,0,0,1,1.223-.588H356.76a1.567,1.567,0,0,1,1.223.588l5.394,6.75a1.573,1.573,0,0,1,.342.977V249.39A6.968,6.968,0,0,1,356.76,256.35Zm-20.6-44.046V249.39a3.833,3.833,0,0,0,3.828,3.828H356.76a3.833,3.833,0,0,0,3.828-3.828V212.3l-4.582-5.732H340.74Z" transform="translate(-333.027 -203.44)" fill="#fff"/>
            </g>
            <g id="グループ_126" data-name="グループ 126" transform="translate(7.871)">
              <g id="グループ_121" data-name="グループ 121" transform="translate(0 15.327)">
                <path id="パス_116" data-name="パス 116" d="M357.333,203.883v-7.061a1.8,1.8,0,0,0-1.8-1.8h-11.45a1.8,1.8,0,0,0-1.8,1.8v7.061Z" transform="translate(-342.288 -195.024)" fill="#fff"/>
              </g>
              <g id="グループ_122" data-name="グループ 122">
                <path id="パス_117" data-name="パス 117" d="M357.333,185.848v-7.061a1.8,1.8,0,0,0-1.8-1.8h-11.45a1.8,1.8,0,0,0-1.8,1.8v7.061Z" transform="translate(-342.288 -176.989)" fill="#fff"/>
              </g>
              <g id="グループ_123" data-name="グループ 123" transform="translate(4.429 6.328)">
                <rect id="長方形_173" data-name="長方形 173" width="6.187" height="12.234" fill="#fff"/>
              </g>
              <g id="グループ_125" data-name="グループ 125" transform="translate(10.74 1.999)">
                <g id="グループ_124" data-name="グループ 124">
                  <path id="パス_118" data-name="パス 118" d="M377.379,187.483a1.8,1.8,0,0,1-1.554-.891l-2.135-3.655H356.723a1.8,1.8,0,1,1,0-3.6h18a1.8,1.8,0,0,1,1.553.892l2.656,4.546a1.8,1.8,0,0,1-1.551,2.7Z" transform="translate(-354.925 -179.341)" fill="#fff"/>
                </g>
              </g>
            </g>
            <g id="グループ_139" data-name="グループ 139" transform="translate(36.485 9.182)">
              <g id="グループ_130" data-name="グループ 130" transform="translate(6.234 3.901)">
                <g id="グループ_127" data-name="グループ 127">
                  <path id="パス_119" data-name="パス 119" d="M383.46,194.384a1.428,1.428,0,0,0,1.876.609,1.348,1.348,0,0,0,.529-1.832l-.043-.079a1.348,1.348,0,0,0-2.361,1.3Z" transform="translate(-383.292 -192.384)" fill="#fff"/>
                </g>
                <g id="グループ_128" data-name="グループ 128" transform="translate(2.567 4.653)">
                  <path id="パス_120" data-name="パス 120" d="M394.305,214.039l.084.153a1.349,1.349,0,1,0,2.362-1.3l-.084-.153a1.349,1.349,0,0,0-2.362,1.3Zm-2.608-4.726.084.152a1.349,1.349,0,1,0,2.362-1.3l-.084-.152a1.349,1.349,0,1,0-2.362,1.3Zm-2.607-4.727.084.152a1.348,1.348,0,1,0,2.361-1.3l-.084-.152a1.348,1.348,0,1,0-2.361,1.3Zm-2.608-4.727.084.153a1.348,1.348,0,0,0,2.361-1.3l-.084-.153a1.348,1.348,0,1,0-2.361,1.3Z" transform="translate(-386.313 -197.859)" fill="#fff"/>
                </g>
                <g id="グループ_129" data-name="グループ 129" transform="translate(13 23.577)">
                  <path id="パス_121" data-name="パス 121" d="M398.756,222.109a1.429,1.429,0,0,0,1.876.609,1.349,1.349,0,0,0,.529-1.832h0a1.429,1.429,0,0,0-1.876-.609A1.349,1.349,0,0,0,398.756,222.109Z" transform="translate(-398.589 -220.126)" fill="#fff"/>
                </g>
              </g>
              <g id="グループ_134" data-name="グループ 134" transform="translate(0 6.417)">
                <g id="グループ_131" data-name="グループ 131">
                  <path id="パス_122" data-name="パス 122" d="M375.983,196.956a1.428,1.428,0,0,0,1.6,1.147,1.348,1.348,0,0,0,1.06-1.586l-.018-.088a1.348,1.348,0,0,0-2.645.527Z" transform="translate(-375.957 -195.344)" fill="#fff"/>
                </g>
                <g id="グループ_132" data-name="グループ 132" transform="translate(1.037 5.212)">
                  <path id="パス_123" data-name="パス 123" d="M380.362,218.973l.034.171a1.349,1.349,0,0,0,2.646-.526l-.034-.171a1.349,1.349,0,0,0-2.646.526Zm-1.053-5.295.034.171a1.348,1.348,0,0,0,2.645-.526l-.034-.171a1.348,1.348,0,1,0-2.645.526Zm-1.053-5.295.034.171a1.348,1.348,0,0,0,2.645-.526l-.034-.171a1.348,1.348,0,1,0-2.645.526Zm-1.053-5.3.034.171a1.348,1.348,0,1,0,2.645-.526l-.034-.171a1.348,1.348,0,0,0-2.645.526Z" transform="translate(-377.177 -201.477)" fill="#fff"/>
                </g>
                <g id="グループ_133" data-name="グループ 133" transform="translate(5.249 26.391)">
                  <path id="パス_124" data-name="パス 124" d="M382.159,228.009a1.428,1.428,0,0,0,1.6,1.148,1.348,1.348,0,0,0,1.059-1.586l-.017-.088a1.349,1.349,0,1,0-2.646.526Z" transform="translate(-382.133 -226.397)" fill="#fff"/>
                </g>
              </g>
              <g id="グループ_138" data-name="グループ 138" transform="translate(11.686)">
                <g id="グループ_135" data-name="グループ 135">
                  <path id="パス_125" data-name="パス 125" d="M392.049,188.194a1.428,1.428,0,0,0-1.972-.011,1.348,1.348,0,0,0,.051,1.906l.065.062a1.348,1.348,0,0,0,1.855-1.957Z" transform="translate(-389.708 -187.794)" fill="#fff"/>
                </g>
                <g id="グループ_136" data-name="グループ 136" transform="translate(3.857 3.625)">
                  <path id="パス_126" data-name="パス 126" d="M406.369,203.622a1.348,1.348,0,0,0,.051,1.906l.126.12a1.349,1.349,0,0,0,1.856-1.957l-.127-.12A1.348,1.348,0,0,0,406.369,203.622Zm-3.918-3.714a1.348,1.348,0,0,0,.051,1.906l.126.12a1.349,1.349,0,1,0,1.856-1.957l-.127-.12A1.348,1.348,0,0,0,402.451,199.908Zm-3.918-3.714a1.348,1.348,0,0,0,.051,1.906l.126.12a1.349,1.349,0,0,0,1.856-1.957l-.127-.12A1.348,1.348,0,0,0,398.534,196.194Zm-3.918-3.715a1.348,1.348,0,0,0,.051,1.906l.127.12a1.348,1.348,0,1,0,1.855-1.957l-.127-.12A1.349,1.349,0,0,0,394.616,192.48Z" transform="translate(-394.246 -192.059)" fill="#fff"/>
                </g>
                <g id="グループ_137" data-name="グループ 137" transform="translate(19.528 18.513)">
                  <path id="パス_127" data-name="パス 127" d="M415.027,209.977a1.349,1.349,0,1,1-1.856,1.957l-.065-.061a1.35,1.35,0,0,1-.05-1.907A1.429,1.429,0,0,1,415.027,209.977Z" transform="translate(-412.686 -209.577)" fill="#fff"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
