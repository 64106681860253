import React from 'react'

export default function ThermoIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 125">
      <g id="manner_thermo" transform="translate(-639 -1376)">
        <circle id="楕円形_5" data-name="楕円形 5" cx="62.5" cy="62.5" r="62.5" transform="translate(639 1376)" fill="#97b1ab"/>
        <g id="グループ_145" data-name="グループ 145" transform="translate(662 1405)">
          <g id="グループ_141" data-name="グループ 141" transform="translate(0)">
            <path id="パス_128" data-name="パス 128" d="M49.645,417.255a3.308,3.308,0,0,1-.349-.018,3.507,3.507,0,0,1-2.389-1.269,3.558,3.558,0,0,1,.433-5.035L67.6,394.245l4.087-9.347a1.619,1.619,0,0,1,.454-.6l38.292-31.543h0a8.365,8.365,0,0,1,10.636,12.913L82.778,397.209a1.628,1.628,0,0,1-.677.33l-9.957,2.221L51.884,416.45A3.506,3.506,0,0,1,49.645,417.255Zm24.855-30.7L70.414,395.9a1.619,1.619,0,0,1-.454.6L49.4,413.434a.3.3,0,0,0-.04.418.356.356,0,0,0,.248.162.278.278,0,0,0,.216-.063l20.559-16.936a1.621,1.621,0,0,1,.677-.331l9.957-2.221,37.993-31.3a5.125,5.125,0,0,0-6.518-7.911Zm-5.571,8.7h0Z" transform="translate(-46.063 -350.859)" fill="#fff"/>
          </g>
          <g id="グループ_142" data-name="グループ 142" transform="translate(1.486 54.396)">
            <path id="パス_129" data-name="パス 129" d="M59.241,415.472l-8.863,7.3A.98.98,0,0,1,49,422.665l-1.036-1.256a.981.981,0,0,1,.159-1.372l8.863-7.3Z" transform="translate(-47.754 -412.736)" fill="#fff"/>
          </g>
          <g id="グループ_143" data-name="グループ 143" transform="translate(30.782 23.047)">
            <path id="パス_130" data-name="パス 130" d="M85.925,395.333a2.194,2.194,0,0,1-1.7-.8l-2.642-3.205a2.209,2.209,0,0,1,.3-3.1L94.8,377.577a2.207,2.207,0,0,1,3.1.3l2.641,3.206a2.2,2.2,0,0,1-.3,3.1L87.324,394.831A2.194,2.194,0,0,1,85.925,395.333Zm-2.238-5.37,2.275,2.761,12.48-10.28-2.276-2.761Zm14.938-7.3Z" transform="translate(-81.079 -377.076)" fill="#fff"/>
          </g>
          <g id="グループ_144" data-name="グループ 144" transform="translate(64.317 1.465)">
            <path id="パス_131" data-name="パス 131" d="M127.946,365.538l1.1-.9a6.727,6.727,0,0,0,.913-9.442l-.19-.231a6.727,6.727,0,0,0-9.441-.912l-1.1.9Z" transform="translate(-119.226 -352.526)" fill="#fff"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
