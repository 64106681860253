import React from 'react'

export default function MaskIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 125">
      <g id="manner_mask" transform="translate(-388 -1311)">
        <circle id="楕円形_4" data-name="楕円形 4" cx="62.5" cy="62.5" r="62.5" transform="translate(388 1311)" fill="#97b1ab"/>
        <g id="グループ_114" data-name="グループ 114" transform="translate(402 1354)">
          <g id="グループ_107" data-name="グループ 107" transform="translate(18.029 0)">
            <path id="パス_107" data-name="パス 107" d="M111.09,239.194H61.4a6.006,6.006,0,0,1-6-6V204.74a6.006,6.006,0,0,1,6-6H111.09a6.006,6.006,0,0,1,6,6v28.453A6.007,6.007,0,0,1,111.09,239.194ZM61.4,202.006a2.737,2.737,0,0,0-2.734,2.734v28.453a2.737,2.737,0,0,0,2.734,2.734H111.09a2.737,2.737,0,0,0,2.733-2.734V204.74a2.737,2.737,0,0,0-2.733-2.734Z" transform="translate(-55.405 -198.741)" fill="#fff"/>
          </g>
          <g id="グループ_108" data-name="グループ 108" transform="translate(24.228 0.376)">
            <path id="パス_108" data-name="パス 108" d="M63.654,238.865a1.256,1.256,0,0,1-1.256-1.256V200.422a1.256,1.256,0,1,1,2.512,0v37.187A1.256,1.256,0,0,1,63.654,238.865Z" transform="translate(-62.398 -199.165)" fill="#fff"/>
          </g>
          <g id="グループ_109" data-name="グループ 109" transform="translate(71.002 0.376)">
            <path id="パス_109" data-name="パス 109" d="M116.422,238.865a1.257,1.257,0,0,1-1.256-1.256V200.422a1.256,1.256,0,1,1,2.513,0v37.187A1.257,1.257,0,0,1,116.422,238.865Z" transform="translate(-115.166 -199.165)" fill="#fff"/>
          </g>
          <g id="グループ_110" data-name="グループ 110" transform="translate(24.228 11.178)">
            <path id="パス_110" data-name="パス 110" d="M110.429,217.279a1.153,1.153,0,0,1-.183-.013l-23.206-3.388-23.205,3.388a1.256,1.256,0,0,1-.363-2.485l23.388-3.415a1.244,1.244,0,0,1,.363,0l23.387,3.415a1.256,1.256,0,0,1-.18,2.5Z" transform="translate(-62.398 -211.352)" fill="#fff"/>
          </g>
          <g id="グループ_111" data-name="グループ 111" transform="translate(24.228 22.61)">
            <path id="パス_111" data-name="パス 111" d="M87.041,230.176a1.125,1.125,0,0,1-.181-.013l-23.388-3.414a1.256,1.256,0,0,1,.363-2.486l23.205,3.389,23.206-3.389a1.256,1.256,0,0,1,.363,2.486l-23.387,3.414A1.129,1.129,0,0,1,87.041,230.176Z" transform="translate(-62.398 -224.249)" fill="#fff"/>
          </g>
          <g id="グループ_112" data-name="グループ 112" transform="translate(0 4.98)">
            <path id="パス_112" data-name="パス 112" d="M50.312,234.851a15.246,15.246,0,1,1,0-30.492v3.266a11.98,11.98,0,1,0,0,23.96Z" transform="translate(-35.065 -204.359)" fill="#fff"/>
          </g>
          <g id="グループ_113" data-name="グループ 113" transform="translate(82.496 4.98)">
            <path id="パス_113" data-name="パス 113" d="M128.133,234.851v-3.266a11.98,11.98,0,0,0,0-23.96v-3.266a15.246,15.246,0,0,1,0,30.492Z" transform="translate(-128.133 -204.359)" fill="#fff"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
