// extracted by mini-css-extract-plugin
export const contents_container = "index-module--contents_container--VoP1g";
export const contents_heading = "index-module--contents_heading--ovB7o";
export const contents_heading_img = "index-module--contents_heading_img--3J9FI";
export const contents_heading_txt = "index-module--contents_heading_txt--NkJlx";
export const mv_container = "index-module--mv_container--1wdEa";
export const mv_heading = "index-module--mv_heading--1SRcN";
export const mv_box = "index-module--mv_box--29fzI";
export const mv_imagebox = "index-module--mv_imagebox--34kYO";
export const covid19_wrapper = "index-module--covid19_wrapper--16mB1";
export const covid19_heading = "index-module--covid19_heading--sAixP";
export const covid19_measures_list = "index-module--covid19_measures_list--2UimX";
export const covid19_announcement_list = "index-module--covid19_announcement_list--1-r4g";
export const machine_container = "index-module--machine_container--2ED_8";
export const machine_heading = "index-module--machine_heading--1tH8L";
export const machine_subheading = "index-module--machine_subheading--srFfI";
export const machine_list = "index-module--machine_list--EQMcd";
export const concept_wrapper = "index-module--concept_wrapper--igV4-";
export const concept_box = "index-module--concept_box--1HUb2";
export const concept_textbox = "index-module--concept_textbox--1MGqj";
export const concept_textbox_heading = "index-module--concept_textbox_heading--11Oc4";
export const concept_textbox_text = "index-module--concept_textbox_text--3Mg2y";
export const point_item = "index-module--point_item--2VLCH";
export const point_image = "index-module--point_image--TpJyn";
export const point_textbox = "index-module--point_textbox--2nXgQ";
export const point_title = "index-module--point_title--1kNtE";
export const point_title_icon = "index-module--point_title_icon--1G_jN";
export const point_title_text = "index-module--point_title_text--WDrVF";
export const point_desc = "index-module--point_desc--dOaTw";
export const course_wrapper = "index-module--course_wrapper--3uGGl";
export const course_list = "index-module--course_list--3hs99";
export const access_wrapper = "index-module--access_wrapper--2clEp";
export const access_box = "index-module--access_box--31NLI";
export const access_imgbox = "index-module--access_imgbox--fjZ2Y";
export const access_txtbox = "index-module--access_txtbox--3k-8c";
export const access_detail_table = "index-module--access_detail_table--3pbgE";
export const sns_wrapper = "index-module--sns_wrapper--cXgv2";
export const sns_container = "index-module--sns_container--1ksbS";
export const sns_anchor = "index-module--sns_anchor--1Snpp";
export const sns_text = "index-module--sns_text--2eE_G";