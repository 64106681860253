import React from 'react'
import * as styles from '../styles/machine.module.scss'

export default function Covid19Measures({machineTitle, machineText, machineImage}) {
  return (
    <li className={styles.machine_item}>
      <div className={styles.machine_img}>
        {machineImage}
      </div>
      <div className={styles.machine_txt}>
        <h3>{machineTitle}</h3>
        <p>
          {machineText.split('\n').map((str, idx) => (
            <React.Fragment key={idx}>{str}<br /></React.Fragment>
          ))}
        </p>
      </div>
    </li>
  )
}
