import React, {useState} from 'react'
import * as styles from '../styles/faq.module.scss'
import QIcon from '../icons/q'

export default function Faq({question, answer}) {
  const [opened, setOpened] = useState(false)

  return (
    <li className={styles.faq_item}>
      <div className={styles.question} onClick={() => setOpened(!opened)}>
        <span className={styles.question_icon}><QIcon /></span>
        <span className={styles.question_text}>{question}</span>
        <span className={`${styles.open_icon} ${opened ? styles.opened : ''}`}></span>
      </div>
      <div className={`${styles.answer} ${opened ? styles.opened : ''}`}>
        {answer}
      </div>
    </li>
  )
}
